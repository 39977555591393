<template>
	<div class="container">
		<div class="policyCompilationTitle">电子政策汇编系统</div>
		<el-breadcrumb separator-class="el-icon-arrow-right" class="navbar">
			<el-breadcrumb-item :to="{ path: '/policyCompilation' }"><el-button type="primary" size="mini">返回</el-button></el-breadcrumb-item>
			<el-breadcrumb-item><el-button type="text" size="mini" style="color: #555;font-size: 14px;">政策申报人数</el-button></el-breadcrumb-item>
		</el-breadcrumb>
		<div class="main">
			<el-table border :data="list" style="width: 100%;">
				<el-table-column label="姓名" align="center" prop="name"></el-table-column>
				<el-table-column label="公司/单位" align="center" prop="company_name" show-overflow-tooltip></el-table-column>
				<el-table-column label="品牌名称" align="center" prop="brand_name"></el-table-column>
				<el-table-column label="电话" align="center" prop="tel"></el-table-column>
				<el-table-column label="邮箱" align="center" prop="email"></el-table-column>
			</el-table>
			<div class="pages">
				<el-pagination
					background
					@current-change="handleCurrentChange"
					:current-page="1"
					:page-count="pageCount"
					layout="total,prev,pager,next,jumper"
					:total="total"
				></el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			list: [],
			id: '',
			currentPage: 1,
			pageCount: Math.ceil(this.total / 10),
			total: 0,
			page: 1,
			limit: 10
		};
	},
	mounted() {
		this.id = this.$route.query.id;
		this.$http.get(this.URL.adminUrl.declare_number + this.id).then(res => {
			this.list = res.data.data.data.data;
		});
	},
	methods: {
		handleCurrentChange(val) {
			this.page = val;
			this.getData();
		}
	}
};
</script>

<style scoped>
.policyCompilationTitle {
	display: flex;
	align-items: flex-start;
	justify-content: center;
	font-size: 18px;
	height: 40px;
}
.navbar {
	margin-bottom: 30px;
	padding: 15px;
	border-bottom: 1px solid #ccc;
}
.pages {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 50px 0;
}

</style>
